<template>
<v-container fluid>
    <v-row dense>

        <template v-if="loading">
            <v-col cols="12" class="text-center" mt-4>
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                <div v-show="sending" class="font-weight-thin">Creating new users and sending invitations. This may take a few minutes.</div>
                <div v-show="!sending" class="font-weight-thin">Refreshing invitee list</div>
            </v-col>
        </template>
        <template v-else>

            <v-card>
            <v-card-title>New Invites</v-card-title>
            <v-card-subtitle>Invitations allow interested customers to login and purchase a membership. Populate the list below by adding one at a time or by copying from a spreadsheet. Then send out the invitations</v-card-subtitle>

            <v-card-actions class="ml-3 mr-3">
                <v-btn outlined small
                    @click="addOne=true"
                >Add invitee</v-btn>
                <v-spacer></v-spacer>
                <v-btn outlined small
                    @click="addBulk=true"
                >Add from spreadsheet</v-btn>
            </v-card-actions>


        
            <v-card-text>
                <v-data-table :headers="headers" :items="invitees" :items-per-page="10" item-key="email" class="elevation-1">
                    <template v-slot:items="props">
                        <td class="text-xs-left">{{ props.item.first }}</td>
                        <td class="text-xs-left">{{ props.item.last }}</td>
                        <td class="text-xs-left">{{ props.item.email }}</td>
                    </template>
                </v-data-table>
            <v-row align="center" justify="center" class="mt-2 mb-5">
                <v-col cols="6">
                <v-select outlined dense color="primary" 
                    v-model="invitationType" 
                    :items="invitationTypeOptions" 
                    label="Select Invitation Type..."
                    hide-details
                    ></v-select>
                </v-col>
                <v-col cols="6">
                <v-btn outlined :disabled="invitationType==null" color="primary" @click="inviteAll" dark>Send Invites</v-btn>
                </v-col>
            </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-title>Outstanding Invites</v-card-title>
            <v-card-subtitle>These are invites that have already been sent. Cancelling invites does not notify the invitee, 
            but deletes their login credentials and user profile that were created to enable them to join</v-card-subtitle>
            <v-card-text>
                <v-data-table 
                    v-model="pendingSelected" 
                    :headers="pendingHeaders" 
                    :items="pendingInvitees" 
                    item-key="email" 
                    class="elevation-1" 
                    show-select
                    >
                    <template slot="items" slot-scope="props">
                        <tr @click="props.expanded = !props.expanded">
                            <td>
                                <v-checkbox
                                v-model="props.selected"
                                primary
                                hide-details
                                ></v-checkbox>
                            </td>
                            <td class="text-xs-left"> {{ props.item.firstName }} </td>
                            <td class="text-xs-left"> {{ props.item.lastName }} </td>
                            <td class="text-xs-left"> {{ props.item.email }} </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="pendingSelected.length>0" outlined color="error" @click="deleteInvitations" :loading="deleting">Cancel Selected Invites</v-btn>
            </v-card-actions>

            </v-card>

            <v-card>
            <v-card-title>Gift a Free Membership</v-card-title>
            <v-card-subtitle>A free membership is not an invitation to purchase a membership. 
                Rather, this immediately makes the customer a full membership for the selected year, free of charge.</v-card-subtitle>

            <v-card-actions class="ml-3 mr-3">
                <v-btn outlined small
                    @click="showGift=true"
                >Gift a Free Membership</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
            </v-card>

            <v-col ma-3>
                <div class="text-center">        
                    <v-btn icon color="primary" @click="refresh" :loading="loading" :disabled="loading">
                        <v-icon>refresh</v-icon>
                    </v-btn>
                </div>
            </v-col>

        </template>

    </v-row>

    <v-dialog v-model="addOne" max-width="500px">
        <v-card flat >
            <v-card-title>Enter new invite</v-card-title>

            <v-card-text>
            <v-text-field
                v-model="addOneFirst"
                label="First Name"
                outlined dense color="primary"
            ></v-text-field>
            <v-text-field
                v-model="addOneLast"
                label="Last Name"
                outlined dense color="primary"
            ></v-text-field>
            <v-text-field
                v-model="addOneEmail"
                label="Email"
                outlined dense color="primary"
            ></v-text-field>

            </v-card-text>
            <v-card-actions>
            <v-btn outlined color="primary" @click="extractInvite(); addOne=false" dark>Add</v-btn>
            <v-btn outlined color="primary" @click="addOne=false" dark>Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="addBulk">
        <v-card flat>
            <v-card-title>Copy from spreadsheet</v-card-title>

            <v-card-subtitle>Copy and paste any number of rows. Three columns are required: first name, last name, email. Do not copy headers.</v-card-subtitle>
            <v-card-text>
            <v-textarea
            v-model="text"
                clearable
                outlined
                rows="3"
                clear-icon="cancel"
                label="Paste from spreadsheet (first | last | email)"
                ></v-textarea>
            </v-card-text>
            <v-card-actions>
            <v-btn outlined color="primary" @click="extractInvitees(); addBulk=false" dark>Add</v-btn>
            <v-btn outlined color="primary" @click="addBulk=false" dark>Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showGift" max-width="600px">
        <v-card flat>
            <v-card-title>Gift a Free Membership To</v-card-title>

            <v-card-text>
            <v-text-field
                v-model="giftFirst"
                label="First Name"
                outlined dense color="primary"
            ></v-text-field>
            <v-text-field
                v-model="giftLast"
                label="Last Name"
                outlined dense color="primary"
            ></v-text-field>
            <v-text-field
                v-model="giftEmail"
                label="Email"
                outlined dense color="primary"
            ></v-text-field>

            <v-select outlined dense color="primary" 
                v-model="giftYear" 
                :items="giftYears" 
                label="Select Membership Year..."
            ></v-select>

            <v-select outlined dense color="primary" 
                v-model="giftTier" 
                :items="giftTiers" 
                label="Select Membership Tier..."
            ></v-select>

            <v-select outlined dense color="primary" 
                v-model="giftReason" 
                :items="giftReasons" 
                label="Select Reason for Free Gift..."
                hide-details
            ></v-select>

            <h4 class="mt-5">Clicking "Gift Membership" below will (1) create a login for this new member, (2) give the member full membership as if 
                they had purchased the membership through the normal onboarding process, and (3) send an email to the person notifying them that they
                have been given a gift membership ("someone has gifted you a membership")</h4>

            </v-card-text>
            <v-card-actions>
            <v-btn outlined color="primary" @click="giftFreeMembership()" dark>Gift Membership</v-btn>
            <v-btn outlined color="primary" @click="clearGift()" dark>Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showDialog" max-width="600px">
        <v-card flat>
            <v-card-title>{{dialogTitle}}</v-card-title>
            <v-card-subtitle>{{dialogMessage}}</v-card-subtitle>
            <v-card-actions>
            <v-btn outlined color="primary" @click="showDialog=false" dark>Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showReports">
        <v-card>
            <v-card-title>Invitation Report</v-card-title>
                <v-card-text>
            <invitation-report :reports="reports" />
        </v-card-text>
        <v-card-actions>
            <v-btn outlined color="primary" @click="showReports=false; clearNewInvites()" dark>Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</v-container>
</template>


<script>
//import {  mapMutations} from 'vuex'
import axios from 'axios'
import AllMixins from '@/mixins/AllMixins.js'
import MembershipMixins from '@/mixins/MembershipMixins.js'
import InvitationReport from './InvitationReport.vue'
export default {
    components: {
        InvitationReport
    },
    data() {
        return {
            text: "",
            headers: [  {text: "First", value: "first"},
                        {text: "Last", value: "last"},
                        {text: "Email", value: "email"}],
            pendingSelected: [],
            pendingHeaders: [
                { text: 'First', align: 'left', sortable: true, value: 'firstName'},
                { text: 'Last', align: 'left', sortable: true, value: 'lastName'},
                { text: 'Email', align: 'left', sortable: true, value: 'email'},
                { text: 'Type', align: 'left', sortable: true, value: 'userType'},
            ],
            invitees: [],
            sending: false,
            deleting: false,
            invitationType: null,
            addOne: false,
            addBulk: false,
            addOneFirst: null,
            addOneLast: null,
            addOneEmail: null,
            showGift: false,
            giftFirst: null,
            giftLast: null,
            giftEmail: null,
            giftYear: null,
            giftTier: null,
            giftTiers: ["Saguaro", "Keystone"],
            giftReason: null,
            giftReasons: ["Membership gifted by company", "Member paid outside of app", "Another person paid for membership as gift outside of app"],
            showDialog: false,
            dialogMessage: "",
            dialogTitle: "",
            reports: [],
            showReports: false,
        }
    },
    mixins: [AllMixins, MembershipMixins],
    computed: {
        // ...mapGetters([
        //     'loading',
        // ]),
        giftYears(){
            let years = []
            let currentYear = new Date().getFullYear()
            for(let i = 0; i < 2; i++){
                years.push(currentYear + i)
            }
            return years
        },
        invitationTypeOptions(){
            let types = ["Invite as Saguaro Member", "Invite as Keystone Member", "Invite as Employee"]
            types[0] = types[0] + " for " + this.activeInviteProductYear
            types[1] = types[1] + " for " + this.activeInviteProductYear
            return types
        }

    },
    mounted(){ 
        this.refresh()
    },
    methods: {
        // ...mapMutations ([
        //     'setLoading'
        // ]),
        async refresh(){
            await this.getAllUsers()
            await this.getMembershipProducts()
        },
        extractInvite(){
            try{
                this.addToList(this.addOneFirst.trim(), this.addOneLast.trim(), this.addOneEmail.trim())
            }catch(e){
                console.log(e)
            }
        },
        extractInvitees(){
         this.invitees = []
            let lines = this.text.split("\n")
            lines.forEach(line => {
                let tokens = line.split("\t")
                if(tokens.length == 3 && this.isEmail(tokens[2])){
                    this.addToList(tokens[0], tokens[1], tokens[2])
                }
            });
            this.text = ""
        },
        isEmail(str){
            return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str)
        },
        addToList(first, last, email){
            this.invitees.push({first: first.trim(), last: last.trim(), email: email.trim()})
        },
        async inviteAll () { //this function is only accessible after sending to admin, enforced by dialog popup sequence
            this.setLoading(true)
            this.sending = true
            let uid = this.$store.state.user.uid
            const year = this.activeInviteProductYear
            //console.log(year)
            if(year == null){
                console.log("No membership product selected")
                this.setLoading(false)
                this.sending = false
                return
            }

            var functionsCall = global.FIREBASE.functions.httpsCallable('sendinvitations')
            let response = await functionsCall(
                {
                    uid: uid, 
                    users: this.invitees, 
                    type: this.invitationType,
                    year: year
                }
            )
            //console.log(response)
            this.reports = response.data.reports
            this.setLoading(false)
            this.sending = false
            this.showReports = true

        }, 
        async giftFreeMembership(){
            this.giftFirst = this.giftFirst.trim()
            this.giftLast = this.giftLast.trim()
            this.giftEmail = this.giftEmail.trim()

            this.setLoading(true)
            this.sending = true
            let uid = this.$store.state.user.uid
            this.showGift = false

            var functionsCall = global.FIREBASE.functions.httpsCallable('giftfreemembership')
            let response = await functionsCall(
                {
                    uid: uid, 
                    user: {first: this.giftFirst, last: this.giftLast, email: this.giftEmail}, 
                    type: this.giftTier.toLowerCase(), 
                    year: this.giftYear,
                    reason: this.giftReason
                }
            )
            console.log(response)
            this.clearGift()
            this.dialogTitle = response.data.status.toUpperCase()
            this.dialogMessage = response.data.message
            this.showDialog = true
            return
        },
        clearGift(){
            this.giftFirst = null
            this.giftLast = null
            this.giftEmail = null
            this.giftYear = null
        //    this.showGift = false
            this.setLoading(false)
            this.sending = false
        },
        clearNewInvites(){
            this.invitees = []
            this.addOneFirst = null
            this.addOneLast = null
            this.addOneEmail = null
        },
        deleteInvitations(users){
            this.deleting = true
            let uid = this.$store.state.user.uid
            let self = this
            const functionServer = ENV.functionsServer()
            return new Promise( function(resolve) {
                axios.post(functionServer + "deleteinvitations", {uid: uid, users: self.pendingSelected})
                .then( function(response) {
                    self.deleting = false
                    self.refresh()
                    resolve(response)
                })
                .catch( error => {
                    console.log(error)
                })
            });  
        }
    },
}
</script>