<template>
<v-container>
    <template v-if="loading">
        <v-col cols="12" class="text-center" mt-4>
        <v-progress-circular
            :width="3"
            color="blue"
            indeterminate
        ></v-progress-circular>
        </v-col>
    </template>
    <template v-else>

        <v-row dense>

            <v-col cols="12" sm="6" >
                <v-row dense>
                    <div class="text-button ma-1">
                        Filter By:
                    </div>
       

                    <v-btn-toggle
                        v-model="filterType"
                        dense tile
                        color="deep-purple accent-3"
                        group
                    >
                        <v-btn value="audience">
                        Audience
                        </v-btn>

                        <v-btn value="criteria">
                        Criteria
                        </v-btn>

                    </v-btn-toggle>
                
                </v-row>

            </v-col>
       

      

            <template v-if="filterType=='audience'">
                <v-col cols="12" sm="6" ml-2 mr-2>
                    <v-select :items="audiences" v-model="audience"
                    outlined dense hide-details
                    label="Audience"
                    ></v-select>
                </v-col>
            </template>
            <template v-else>

                <v-col cols="12" sm="6" md="3" ml-2 mr-2>
                    <v-select :items="membershipYearOptions" v-model="currentMembershipYear"
                    outlined dense hide-details
                    label="Membership Year"
                    ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3" ml-2 mr-2>
                    <v-select :items="membershipTierOptions" v-model="currentMembershipTier"
                    outlined dense hide-details
                    label="Membership Tier"
                    ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3" ml-2 mr-2>
                    <v-select :items="membershipStatusOptions" v-model="currentMembershipStatus"
                    outlined dense hide-details
                    label="Membership Status"
                    ></v-select>
                            </v-col>
                <v-col cols="12" sm="6" md="3" ml-2 mr-2>
                    <v-select :items="newOrRenewOptions" v-model="newOrRenew" 
                    outlined dense hide-details
                    label="Membership History"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" ml-2 mr-2>
                    <v-select :items="['All', 'Invite to Keystone', 'Invite to Saguaro']" v-model="nextYearInvitation" 
                    outlined dense hide-details
                    label="Next Year Invitation"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" ml-2 mr-2>
                    <v-select :items="['All','Yes','No']" v-model="extensionFilter" 
                    outlined dense hide-details
                    label="Orders Extended"
                    ></v-select>
                </v-col>
            </template>

        </v-row>
        <v-col xs6 ml-3 mb-4>
            <div class="h3">Members: {{ filteredMembers.length + " " + (selected.length>0?" (Selected: " + selected.length + ")":"")}}</div>
        </v-col>
        <v-row dense>
            <v-col cols="12" sm="6" mt-0 ml-2 mr-2>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search members by name or #"
                    outlined dense
                    hide-details
                ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="ma-0 mt-2 mr-2 mb-0" >
                <v-btn
                color="primary" :outlined="!showSelect" small
                    @click="showSelect=!showSelect"
                >
                <v-icon>mdi-gesture-tap</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="3" class="ma-0 mt-2 mr-2" >
                <v-menu >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        color="primary" outlined small
                        v-bind="attrs"
                        v-on="on"
                        >
                        Actions <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item
                        v-for="(item, index) in rightClickActions"
                        :key="index"
                        @click="doRightClickAction(item)"
                        >
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>

        </v-row>

        <v-col cols="12">
            <v-card raised>
                <v-data-table
                    :headers="headers"
                    :items="filteredMembers"
                    :mobile-breakpoint="0"
                    item-key="uid"
                    :expanded="expanded"
                    :search="search"
                    :show-select="showSelect"
                    v-model="selected"
                    @click:row="expandRow"
                >
                    <template v-slot:expanded-item="{ item }">
                        <td :colspan="headers.length">
                            <div style="display: flex; justify-content: center; width: 100%;">
                                <membership-actions 
                                    :member="item"
                                    item-key="uid"
                                />
                            </div>
                        </td>
                    </template>

                    <v-alert slot="no-results" :value="true" color="error" icon="warning">
                        Your search for "{{ search }}" found no results.
                    </v-alert>
                    
                </v-data-table>
            </v-card>
            <v-col>
                <VueJsonToCsv
                    :json-data="filteredMembers"
                    :csv-title="exportName"
                >
                    <v-btn color="primary" outlined class="mr-6">
                        Export Data <i class="mdi mdi-export" aria-hidden="true"></i>
                    </v-btn>
                </VueJsonToCsv>
            </v-col>
                <v-spacer></v-spacer>
                <v-col>
                <v-btn color="primary" outlined class="mr-6" @click="getMemberSpending">
                    Get Member Spending <i class="mdi mdi-currency-usd" aria-hidden="true"></i>
                </v-btn>
            </v-col>
        </v-col>

        <v-col>

        </v-col>

        <v-col ma-3>
            <div class="text-center">        
                <v-btn icon color="primary" @click="refresh" :loading="loading" :disabled="loading">
                    <v-icon>refresh</v-icon>
                </v-btn>
            </div>
        </v-col>
    </template>
</v-container>
</template>

<script>
import moment from 'moment-timezone'
import MembershipActions from './MembershipActions.vue'
//import Invitations from './Invitations.vue'
//import Renewals from './Renewals.vue'
import VueJsonToCsv from 'vue-json-to-csv'
import AllMixins from '@/mixins/AllMixins.js'
export default {
    data () {
        return {
            search: '',
            selected: [],
            showSelect: false,
            rightClickActions: [
                {text: "Add Invite to Keystone", action: (user) => this.$set(user, 'ksInvite', true)}, 
                {text: "Remove Invite to Keystone", action: (user) => this.$set(user, 'ksInvite', false)}],
            headersInfoOnly: [
                { width: '1%', text: '#', align: 'left', sortable: true, value: 'memberId' },
                { text: 'First', align: 'left', sortable: true, value: 'firstName' , },
                { text: 'Last', align: 'left', sortable: true, value: 'lastName',  },
                { text: 'Email', align: 'left', sortable: true, value: 'email',  },
                { text: 'Proxy', align: 'left', sortable: true, value: 'proxy',  },
            //    { text: 'Keystone Invite', align: 'left', sortable: true, value: 'ksInvite',  },
            ],
            headersWithSpending: [
                { width: '1%', text: '#', align: 'left', sortable: true, value: 'memberId' },
                { text: 'First', align: 'left', sortable: true, value: 'firstName' , },
                { text: 'Last', align: 'left', sortable: true, value: 'lastName',  },
                { text: 'Email', align: 'left', sortable: true, value: 'email',  },
                { text: '# Orders', align: 'right', sortable: true, value: 'numOrders',  },
                { text: 'Total Spend', align: 'right', sortable: true, value: 'totalPaid2023',  },
             //   { text: 'Keystone Invite', align: 'left', sortable: true, value: 'ksInvite',  },
            ],
            spendingLoaded: false,
            filterType: "criteria",
            currentMembershipYear: "",
            membershipStatusOptions: ["Member", "Invitee", "Admin"],
            currentMembershipStatus: "Member",
            membershipTierOptions: ["All", "Saguaro", "Keystone"],
            currentMembershipTier: "All",
            nextYearInvitation: "All",
            extensionFilter: "All",
            newOrRenew: "All",
            expanded: [],
            extendSwitch: false,
            extensionUpdateLoading: false,
            editMember: "",
            enableGift: false,
            giftee: {},
            membershipYearOptions: ["All", "2019", "2020", "2021", "2022", "2023", "2024"],
            membershipStatusOptions: ["Member", "Invitee"],
            newOrRenewOptions: ["All", "Renewed From Last Year", "Not Renewed Next Year", "New Additions Only"],
            showMembers: true,
         //   loading: false,
            confirmGift: false,
            giftLoading: false,
            queryFutureMembers: true,
        }
    },
    components: {
        MembershipActions,
      //  Invitations,
      //  Renewals,
        VueJsonToCsv
    },
    mixins: [AllMixins],
    mounted () {
        this.currentMembershipYear = new Date().toISOString().substring(0,4)
        this.refresh()
    },
    watch: {
        filterType: function (val) {
            console.log(val)
        },
    },
    computed: {
        headers () {
            let dynamicHeaders = []
            if(this.spendingLoaded)
                dynamicHeaders = this.headersWithSpending
            else
                dynamicHeaders = this.headersInfoOnly
            if(this.$vuetify.breakpoint.xsOnly)
                return dynamicHeaders.slice(0,3)
            else
                return dynamicHeaders
        },
        filteredMembers () {
         //   console.log(this.members)
            let filtered = []
            if(this.filterType == 'audience'){
                filtered = this.getMembersForAudience(this.audience)
            }
            else{
                for (const i in this.members) {
                    if(this.includeMember(this.members[i], this.currentMembershipYear, this.currentMembershipTier, 
                    this.currentMembershipStatus, this.newOrRenew, this.extensionFilter, this.nextYearInvitation)){
                        filtered.push(this.members[i]);
                    }
                }
            }
            if(filtered == undefined)
                return []
            return filtered
        },
        filteredExport () {
            let filteredOrders = []
            this.filteredMembers.forEach( member => {
                member.openOrders.forEach( order => {
                    filteredOrders.push({
                        "dateOrdered": order.dateOrdered,
                        "lastName": member.lastName,
                        "firstName": member.firstName,
                        "productId": order.productId,
                        "variation": order.line_items[0].variation_name,
                        "qty": order.line_items[0].quantity,
                        "total": (order.payment.amount/100.0).toLocaleString("en-US", {style:"currency", currency:"USD"})
                    })
                })
            })
            return filteredOrders
        },
        exportName () {
            return "botls_members_export_" + moment.now()
        },
    },
    methods: {
        expandRow (item) {
            // would be
            // this.expanded = [item]
            // but if you click an expanded row, you want it to collapse
            this.expanded = item === this.expanded[0] ? [] : [item]
        },
        refresh () {
           this.getAllUsers()
           this.getAvailableProducts()
        },
        calendar (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        doRightClickAction(item){
            //console.log(item)
            if(this.selected != null && this.selected.length > 0){
                this.selected.forEach (member => {
                    console.log(member)
                     item.action(member)
                     this.updateMember(member)
                })
                this.selected = []
            }
            // else{
            //     item.action(this.rightClickedItem)
            //     this.updateMember(this.rightClickedItem)
            // }
        },
        updateMember (user) {                
            if(user.uid){   //user exists
                global.FIREBASE.db.collection("users").doc(user.uid).update(user)
                .then(() => {
                    console.log("Database updated for user " + user.uid)
                })
                .catch((error) => {
                    console.error("Error updating record: " + user.uid, error)
                })
            }
        },

        async getMemberSpending () {
            this.setLoading(true)
            this.spendingLoaded = false
            var functionsCall = global.FIREBASE.functions.httpsCallable('membersalesreport')
            let result = await functionsCall({year: this.currentMembershipYear})
            this.setLoading(false)
            if(result.data.status == "success"){
                this.members = result.data.members
                this.spendingLoaded = true
            }
            else{
                console.log(result.data.error)
                this.members = []
            }
        

        },

    }
}
</script>
<style>
@media only screen and (max-width: 500px) {
  .responsive-card {
    max-width: 100%; /* Adjust this as needed */
  }
}
/* For wider screens */
.responsive-card {
  max-width: 500px;
}
</style>