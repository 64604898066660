<template>
          <v-container v-if="reports!=null && reports.length > 0">
            <v-row>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Email</th>
                     
                        <th>Has Prior Account</th>
                        <th>Found User Info</th>
                        <th>Set User Info</th>
                        <th>Sent Invitation Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="report in reports" :key="report.email">
                        <td>{{ report.report.report.email }}</td>
                       
                        <td v-html="getStatusIcon(report.report.report['has prior account'])"></td>
                        <td v-html="getStatusIcon(report.report.report['found user info'])"></td>
                        <td v-html="getStatusIcon(report.report.report['set user info'])"></td>
                        <td v-html="getStatusIcon(report.report.report['sent invitation email'])"></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>

  </template>
  <script>
  export default {
    data() {
      return {
       // dialog: false, // Controls the visibility of the dialog
      };
    },
    props: {
      // The `reports` prop is passed from the parent component
      reports: {
        type: Array,
        required: true
      }
    },
    methods: {
        getStatusIcon(value) {
            if (value === true) {
                return '<span class="status-icon success">&#10004;</span>'; // Green check
            } else if (value === false) {
                return '<span class="status-icon error">&#10006;</span>'; // Red X
            }
            return ''; // Return empty string if value is neither true nor false
        }
    }
  };
  </script>
  <style scoped>
  .status-icon.success {
    color: green;
  }
  
  .status-icon.error {
    color: red;
  }
  </style>
  
  
  