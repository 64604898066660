<template>
    <v-container fluid>
        <v-row row wrap>



                <v-tabs grow>

                    <v-tab >Roster</v-tab>
                    <v-tab-item>
                        <roster/>
                    </v-tab-item>
                  
                    <v-tab >Renewals</v-tab>
                    <v-tab-item>
                        <renewals/>
                    </v-tab-item>

                    <v-tab >Invitations</v-tab>
                    <v-tab-item>
                        <invitations/>
                    </v-tab-item>


                </v-tabs>

        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment-timezone'
import MembershipActions from './MembershipActions.vue'
import Roster from './Roster.vue'
import Invitations from './Invitations.vue'
import Renewals from './Renewals.vue'
import VueJsonToCsv from 'vue-json-to-csv'
export default {
    data () {
        return {

        }
    },
    components: {
        MembershipActions,
        Roster,
        Invitations,
        Renewals,
        VueJsonToCsv
    },
    mounted () {

    },
    computed: {

    },
    methods: {




    }
}
</script>